.mini-drawer {
	color: $brand_graphite-100;

	.MuiPaper-root {
		border-radius: 0;
		padding-top: 165px;

		.MuiListItem-root {
			.MuiTypography-root {
				color: $brand_graphite-100;
			}

			&.active {
				background-color: $brand_surprise;

				.MuiTypography-root {
					color: #fff;
				}
			}
		}
	}
}
