.banner {
	background: linear-gradient(180deg, #b5022a 0%, #95052a 100%);
	height: auto;
	text-align: center;
	padding: 0 2em;

	.banner-container {
		max-width: 1440px;
		display: flex;
		width: 100% !important;
		margin: 0 auto;
		padding: 42px 0;
		align-items: center;
		justify-content: space-between;

		.banner-heading {
			text-align: left;
			color: rgba(255, 255, 255, 1);
			font-size: 48px;
			margin: 0;
		}
	}
}
