$bg: #f9f8f8;
$bg-surface: #ffffff;
$primary: #aa052d;
$secondary: #fa551e;
$danger: #f60e08;
$success: #4ece6c;
$warning: #ff780f;
$info: #00a0ff;
$text: #676767;
$text-dark: #363636;
$text-helper: #787272;
$muted: rgb(230, 230, 230);
$muted-light: #f2f2f2;
$divider: #cac7c7;
$white: #ffffff;
// absa brand
$brand_surprise: #af144b;
$brand_calm: #870a3c;
$brand_care: #f05a7d;
$brand_smile: #f0325a;
$brand_luxury: #640032;
$brand_depth: #500a28;
$brand_energy: #ff780f;
$brand_prepared: #fa551e;
$brand_agile: #f52d28;
$brand_passion: #dc0032;
$brand_warmth: #be0028;
$brand_human: #aa052d;
$brand_grounded: #960528;
$brand_info: #00a0ff;
$brand_error: #f60e08;
$brand_warning: #ff780f;
$brand_success: #31540d;
$brand_graphite-100: #2d2323;
$brand_graphite-82: #524a4a;
$brand_graphite-64: #787272;
$brand_graphite-48: #9a9595;
$brand_graphite-35: #b5b2b2;
$brand_graphite-25: #cac7c7;
$brand_graphite-19: #d7d5d5;
$brand_graphite-13: #e3e2e2;
$brand_graphite-8: #eeeded;
$brand_graphite-5: #f4f3f3;
$brand_graphite-3: #f9f8f8;

// New marketing changes
$brand_passion: #dc0037;
$brand_power: #b50232;
$brand_hope: #95052a;
$brand_inspire: #77021e;
$brand_energy: #ff780f;
$brand_uplift: #f93f24;
$brand_enrich: #131010;
$brand_serene: #ffffff;

$brand_natural-1: #6a3113;
$brand_natural-2: #904420;
$brand_natural-3: #bf642e;
$brand_natural-4: #d68551;
$brand_natural-5: #ec9d63;
$brand_natural-6: #edad7f;
$brand_natural-7: #f0c8a5;

$brand_grass: #548c24;
$brand_moss: #00635f;
$brand_sky: #78bbfa;
$brand_navy: #004491;

$brand_grey-1: #ece9e9;
$brand_grey-2: #d9d9d9;
$brand_grey-3: #bdbcbc;
$brand_grey-4: #a19f9f;
$brand_grey-5: #7b7979;
$brand_grey-6: #565252;
$brand_grey-7: #3a3535;
