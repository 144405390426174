.admin-breadcrumbs {
	position: fixed;
	top: 65px;
	left: 0;
	right: 0;
	background-color: white;
	z-index: 1201;
	box-shadow: 0px 2px 6px 2px #00000012;

	.main-grid {
		padding: 23px;
		display: flex;
		flex: auto;
		justify-content: flex-start;
		align-items: center;
	}
}

.program-detail-admin {
	padding: 110px 0 0 67px !important;
}
