.absa-cdp-ce-ui {
	> header.MuiAppBar-root {
		border-radius: 0 !important;
		.header-menu-item {
			&.active {
				p {
					color: $brand_hope !important;
					font-weight: 600;
				}
			}
			&:hover {
				.-mask-svg {
					fill: $brand_care;
				}
				.-cdp-ce-icon {
					fill: $brand_care;
					color: $brand_care;
				}
				p {
					color: $brand_care !important;
				}
			}
			max-height: $header-height;
			> div {
				box-sizing: border-box;
			}
		}
	}
}
.-sidebar-main {
	.-sidebar-main-item {
		&:hover {
			color: $brand_care !important;
			.-cdp-ce-icon {
				color: $brand_care !important;
				fill: $brand_care !important;
			}
			.span {
				color: $brand_care !important;
			}
			.-cdp-aside-menu-item:not(.focused) {
				color: $brand_care !important;
				fill: $brand_care !important;
			}
		}
		&.-focused {
			color: $bg-surface !important;
			.-cdp-ce-icon {
				color: $bg-surface !important;
				fill: $bg-surface !important;
			}
		}
	}
}
